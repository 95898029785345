@import "../../../node_modules/bootstrap/dist/css/bootstrap.css";
@import "../../../node_modules/font-awesome/css/font-awesome.css";

body {
  overflow-x: hidden;
  font-family: "Roboto Slab", "Helvetica Neue", Helvetica, Arial, sans-serif;
  webkit-tap-highlight-color: #e00c1a;
}

.text-muted {
  color: #777;
}

.text-primary {
  color: #e00c1a;
}

p {
  font-size: 14px;
  line-height: 1.75;
}

p.large {
  font-size: 16px;
}

a,
a:hover,
a:focus,
a:active,
a.active {
  outline: none;
}

a {
  color: #e00c1a;
}

a:hover,
a:focus,
a:active,
a.active {
  color: #b00914;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}

.img-centered {
  margin: 0 auto;
}

.bg-light-gray {
  background-color: #f7f7f7;
}

.bg-darkest-gray {
  background-color: #222;
}

.btn-primary {
  color: white;
  background-color: #e00c1a;
  border-color: #e00c1a;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: white;
  background-color: #b00914;
  border-color: #a60913;
}

.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #e00c1a;
  border-color: #e00c1a;
}

.btn-primary .badge {
  color: #e00c1a;
  background-color: white;
}

.btn-xl {
  color: white;
  background-color: #e00c1a;
  border-color: #e00c1a;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 3px;
  font-size: 18px;
  padding: 20px 40px;
}

.btn-xl:hover,
.btn-xl:focus,
.btn-xl:active,
.btn-xl.active,
.open .dropdown-toggle.btn-xl {
  color: white;
  background-color: #b00914;
  border-color: #a60913;
}

.btn-xl:active,
.btn-xl.active,
.open .dropdown-toggle.btn-xl {
  background-image: none;
}

.btn-xl.disabled,
.btn-xl[disabled],
fieldset[disabled] .btn-xl,
.btn-xl.disabled:hover,
.btn-xl[disabled]:hover,
fieldset[disabled] .btn-xl:hover,
.btn-xl.disabled:focus,
.btn-xl[disabled]:focus,
fieldset[disabled] .btn-xl:focus,
.btn-xl.disabled:active,
.btn-xl[disabled]:active,
fieldset[disabled] .btn-xl:active,
.btn-xl.disabled.active,
.btn-xl[disabled].active,
fieldset[disabled] .btn-xl.active {
  background-color: #e00c1a;
  border-color: #e00c1a;
}

.btn-xl .badge {
  color: #e00c1a;
  background-color: white;
}

.navbar-default {
  background-color: #222;
  border-color: transparent;
}

.navbar-default .navbar-brand {
  color: #e00c1a;
  font-family: "Kaushan Script", "Helvetica Neue", Helvetica, Arial, cursive;
}

.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus,
.navbar-default .navbar-brand:active,
.navbar-default .navbar-brand.active {
  color: #b00914;
}

.navbar-default .navbar-collapse {
  border-color: rgba(255, 255, 255, 0.02);
}

.navbar-default .navbar-toggle {
  background-color: #e00c1a;
  border-color: #e00c1a;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: white;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #e00c1a;
}

.navbar-default .nav li a {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;
  color: white;
}

.navbar-default .nav li a:hover,
.navbar-default .nav li a:focus {
  color: #e00c1a;
  outline: none;
}

.navbar-default .navbar-nav > .active > a {
  border-radius: 0;
  color: white;
  background-color: #e00c1a;
}

.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: white;
  background-color: #b00914;
}

@media (min-width: 768px) {
  .navbar-default {
    background-color: transparent;
    padding: 25px 0;
    -webkit-transition: padding 0.3s;
    -moz-transition: padding 0.3s;
    transition: padding 0.3s;
    border: none;
  }

  .navbar-default .navbar-brand {
    font-size: 2em;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }

  .navbar-default .navbar-nav > .active > a {
    border-radius: 3px;
  }

  .navbar-default.navbar-shrink {
    background-color: #222;
    padding: 10px 0;
  }

  .navbar-default.navbar-shrink .navbar-brand {
    font-size: 1.5em;
  }
}

header {
  background-image: url("../images/header.png");
  background-repeat: none;
  background-attachment: scroll;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  text-align: center;
  color: white;
}

header .intro-text {
  padding-top: 100px;
  padding-bottom: 50px;
}

header .intro-text .intro-lead-in {
  font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-size: 22px;
  line-height: 22px;
  margin-bottom: 25px;
}

header .intro-text .intro-heading {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  header .intro-text {
    padding-top: 300px;
    padding-bottom: 200px;
  }

  header .intro-text .intro-lead-in {
    font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: italic;
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 25px;
  }

  header .intro-text .intro-heading {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 65px;
    line-height: 65px;
    margin-bottom: 50px;
  }
}

section {
  padding: 100px 0;
}

section h2.section-heading {
  font-size: 40px;
  margin-top: 0;
  margin-bottom: 15px;
}

section h3.section-subheading {
  font-size: 16px;
  font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: none;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 75px;
}

@media (min-width: 768px) {
  section {
    padding: 150px 0;
  }
}

.service-heading {
  margin: 15px 0;
  text-transform: none;
}

#our-agencies .agency-item {
  margin: 0 0 15px;
  right: 0;
}

#our-agencies .agency-item .agency-link {
  display: block;
  position: relative;
  max-width: 400px;
  margin: 0 auto;
}

#our-agencies .agency-item .agency-link .agency-hover {
  background: rgba(224, 12, 26, 0.9);
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all ease 0.5s;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
}

#our-agencies .agency-item .agency-link .agency-hover:hover {
  opacity: 1;
}

#our-agencies .agency-item .agency-link .agency-hover .agency-hover-content {
  position: absolute;
  width: 100%;
  height: 20px;
  font-size: 20px;
  text-align: center;
  top: 50%;
  margin-top: -12px;
  color: white;
}

#our-agencies .agency-item .agency-link .agency-hover .agency-hover-content i {
  margin-top: -12px;
}

#our-agencies .agency-item .agency-link .agency-hover .agency-hover-content h3,
#our-agencies .agency-item .agency-link .agency-hover .agency-hover-content h4 {
  margin: 0;
}

#our-agencies .agency-item .agency-caption {
  max-width: 400px;
  margin: 0 auto;
  background-color: white;
  text-align: center;
  padding: 25px;
}

#our-agencies .agency-item .agency-caption h4 {
  text-transform: none;
  margin: 0;
}

#our-agencies .agency-item .agency-caption p {
  font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-size: 16px;
  margin: 0;
}

#our-agencies * {
  z-index: 2;
}

@media (min-width: 767px) {
  #our-agencies .agency-item {
    margin: 0 0 30px;
  }
}

section#contact {
  background-color: #222;
  background-image: url("../images/old-school-car.png");
  background-position: center;
  background-repeat: no-repeat;
}

section#contact .section-heading {
  color: white;
}

section#contact .form-group {
  margin-bottom: 25px;
}

section#contact .form-group input,
section#contact .form-group textarea {
  padding: 20px;
}

section#contact .form-group input.form-control {
  height: auto;
}
section#contact .form-group textarea.form-control {
  height: 236px;
}

section#contact .form-control:focus {
  border-color: #e00c1a;
  box-shadow: none;
}

section#contact ::-webkit-input-placeholder {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #bbbbbb;
}

section#contact :-moz-placeholder {
  /* Firefox 18- */
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #bbbbbb;
}

section#contact ::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #bbbbbb;
}

section#contact :-ms-input-placeholder {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #bbbbbb;
}

section#contact .text-danger {
  color: #e74c3c;
}

footer {
  padding: 25px 0;
  text-align: center;
}

footer span.copyright {
  line-height: 40px;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  text-transform: none;
}

footer ul.quicklinks {
  margin-bottom: 0;
  line-height: 40px;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
}

ul.social-buttons {
  margin-bottom: 0;
}

ul.social-buttons li a {
  display: block;
  background-color: #222;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  font-size: 20px;
  line-height: 40px;
  color: white;
  outline: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

ul.social-buttons li a:hover,
ul.social-buttons li a:focus,
ul.social-buttons li a:active {
  background-color: #e00c1a;
}

.btn:focus,
.btn:active,
.btn.active,
.btn:active:focus {
  outline: none;
}

.agency-modal .modal-content {
  border-radius: 0;
  background-clip: border-box;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  min-height: 100%;
  padding: 0 0;
  text-align: center;
}

.agency-modal .modal-content h2 {
  margin-bottom: 15px;
  font-size: 3em;
}

.agency-modal .modal-content p {
  margin-bottom: 30px;
}

.agency-modal .modal-content p.item-intro {
  margin: 20px 0 30px;
  font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-size: 16px;
}

.agency-modal .modal-content ul.list-inline {
  margin-bottom: 30px;
  margin-top: 0;
}

.agency-modal .modal-content img {
  margin-bottom: 30px;
}

.agency-modal .close-modal {
  position: absolute;
  width: 75px;
  height: 75px;
  background-color: transparent;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.agency-modal .close-modal:hover {
  opacity: 0.3;
}

.agency-modal .close-modal .lr {
  height: 75px;
  width: 1px;
  margin-left: 35px;
  background-color: #222;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Safari and Chrome */
  z-index: 1051;
}

.agency-modal .close-modal .lr .rl {
  height: 75px;
  width: 1px;
  background-color: #222;
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  /* IE 9 */
  -webkit-transform: rotate(90deg);
  /* Safari and Chrome */
  z-index: 1052;
}

.agency-modal .modal-backdrop {
  opacity: 0;
  display: none;
}

@media (max-width: 768px) {
  .agency-modal .modal-content {
    padding: 100px 0;
  }
}

::-moz-selection {
  text-shadow: none;
  background: #e00c1a;
}

::selection {
  text-shadow: none;
  background: #e00c1a;
}

img::selection {
  background: transparent;
}

img::-moz-selection {
  background: transparent;
}

.flexible-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.flexible-container iframe,
.flexible-container object,
.flexible-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.spacer {
  margin-top: 40px;
}

.dropdown-menu {
  background-color: black;
  border-radius: 0.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.navbar-shrink .dropdown-menu {
  background-color: #222;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background-color: rgba(0, 0, 0, 0);
}

.navbar-shrink .dropdown-menu > li > a:hover,
.navbar-shrink .dropdown-menu > li > a:focus {
  background-color: #222;
}

.pagination > li > a,
.pagination > li > a,
.pagination > li > span,
.pagination > li > span {
  z-index: 3;
  color: #e00c1a;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  z-index: 3;
  color: #b00914;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: #b00914;
  border-color: #b00914;
}

.search {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.form-control:focus {
  border-color: #ff0000;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
}

.table th {
  text-align: center;
}

.table tr {
  height: 60px;
}

.table td {
  vertical-align: middle !important;
  display: table-cell;
}

.top-buffer {
  margin-top: 30px;
}

.logo {
  margin-left: 15px;
}

.logo-img {
  height: 75px;

  -webkit-transition: height 250ms;
  -moz-transition: height 250ms;
  -ms-transition: height 250ms;
  -o-transition: height 250ms;
  transition: height 250ms;
}

.navbar-shrink .logo-img {
  height: 50px;
}
